.lottieLoaderBlock{
  position: absolute;
  height: 100%;
  width: 100%;
  left: -5px;
  top: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  perspective: 1000px;
}

.lottieLoader{
  width: 350px !important;
}