.gmnoprint {
  display: none;
}

.bb-header-1 {
  display: flex;
  margin-bottom: 24px;
  width: 100%;
  border-bottom: 1px solid #d2ddec;
  div.col-sm-12.col-md-4,
  h2 {
    width: 100%;
  }
}

.site-appearance {
  position: relative;
  .mtop {
    margin-top: 24px !important;
  }
  .input-block {
    max-width: 500px;
  }
  max-width: 800px;
  .bd-highlight.color-input-field {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .ant-col.ant-form-item-label {
    text-align: left;
    width: 100% !important;
    font-weight: 600;
  }
  .input-preview-image {
    width: 100%;
    height: 140px;
    border: 1px solid #d2ddec;
    border-radius: 10px;
    margin-top: 6px;
    display: none;
    align-items: center;
    justify-content: center;
    background-size: cover !important;
    background-position: center !important;
  }
  .form-file-block {
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }
  .form-file {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
  }
  .form-file-button {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    padding: 0 12px;
    height: 100%;
    font-size: 14px;
    color: #8e8e8e;
  }

  .input-file-name {
    font-size: 0.9rem;
  }
  .form-input {
    width: 100%;
    margin-top: 6px;
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 0.75rem;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #12263f;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d2ddec;
    border-radius: 0.375rem;
    // font-family: CerebriSans;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .form-input-block {
    position: relative;
  }

  .input-title {
    position: relative;
    font-size: 1rem;
    font-weight: 600;
  }
  .input-desc {
    position: relative;
    color: #8e8e8e;
    font-size: 0.9375rem;
    margin-top: 2px;
  }
}

.auth-container {
  label {
    font-weight: 600;
    margin-bottom: 4px !important;
  }
}

.swal2-title {
  color: #262626 !important;
}
.swal2-styled.swal2-confirm {
  background-color: #262626 !important;
  color: white !important;
}

.swal2-styled.swal2-deny {
  background-color: #8e8e8e !important;
  color: white !important;
}

.noDataFound {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #262626;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 10px;
}

.website-manager {
  position: relative;
  max-width: 100%;
  .bd-highlight.color-input-field {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .ant-col.ant-form-item-label {
    text-align: left;
    width: 100% !important;
    font-weight: 600;
    margin-bottom: 20px !important;
  }
  .input-preview-image {
    width: 100%;
    height: 140px;
    border: 1px solid #d2ddec;
    border-radius: 10px;
    margin-top: 16px;
    // display: none;
    align-items: center;
    justify-content: center;
    background-size: cover !important;
    background-position: center !important;
  }
  .form-file-block {
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }
  .form-file {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
  }
  .form-file-button {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    padding: 0 12px;
    height: 100%;
    font-size: 14px;
    color: #8e8e8e;
  }

  .input-file-name {
    font-size: 0.9rem;
  }
  .form-input {
    width: 100%;
    margin-top: 6px;
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 0.75rem;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #12263f;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d2ddec;
    border-radius: 0.375rem;
    // font-family: CerebriSans;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .form-input-block {
    position: relative;
  }

  .input-title {
    position: relative;
    font-size: 1rem;
    font-weight: 600;
  }
  .input-desc {
    // position: relative;
    color: #8e8e8e;
    font-size: 0.9375rem;
    margin-top: 5px;
    position: absolute;
    top: 15px;
    margin-bottom: 10px;
  }
  h5 {
    font-size: 1.25rem;
  }
  h6 {
    font-size: 1.6rem;
  }
  .ml-125 {
    margin-left: 125px;
  }
}

.login-link {
  min-width: 100px;
  display: flex;
  text-decoration: underline;
  color: #007bff;
}

.reports {
  .section-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    .bg-drop {
      background: rgba(255, 255, 255, 0.7);
      z-index: 0;
      position: absolute;
      height: 100%;
      width: 100%;
    }
    .ant-spin {
      z-index: 10 !important;
      perspective: 1000px !important;
    }
  }
  .ant-table-row:last-child {
    .ant-table-cell {
      font-weight: 600;
    }
  }
}

.change-password {
  .kit__utils__heading {
    padding-bottom: 16px;
    border-bottom: 1px solid #d2ddec;
    h5 {
      font-weight: 600 !important;
      letter-spacing: -0.02em;
      font-size: 1.625rem;
    }
  }
  .pass-block {
    display: block !important;
    max-width: 400px !important;
  }
  .with-icon {
    position: relative;
    .password-show {
      position: absolute;
      top: 7px;
      right: 1px;
      background: #f0f5f8;
      height: calc(100% - 8px);
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 1;
      perspective: 1000px;
      border-radius: 0 0.375rem 0.375rem 0;
    }
  }
}

.online-order-setup {
  .mh-t {
    min-height: 120px;
  }
  .pos-r {
    position: relative;
    .overlay-block {
      position: absolute;
      width: calc(100% - 314px);
      height: 100%;
      display: flex;
      left: 314px;
      align-items: center;
      justify-content: center;
      z-index: 10;
      .overlay-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: 0.95;
      }
      .overlay-content {
        z-index: 11;
        text-align: center;
        p {
          font-size: 24px;
          font-weight: 500;
        }
      }
    }
  }
  .form-footer {
    position: sticky !important;
    z-index: 11;
  }
}

@media only screen and (max-width: 500px) {
  .ant-table-content {
    overflow-x: auto;
  }
  .order-history-page {
    .text-right {
      margin-bottom: 20px;
    }
  }

  .online-order-setup {
    .row.pdrht {
      padding-right: 0 !important;
      .card .card-body {
        .d-flex.mb-2.header {
          position: relative;
          max-width: 100%;
        }
        .d-flex.bd-highlight.field {
          margin-left: 0%;
        }
        .ml-auto.bd-highlight.mini {
          max-width: 140px;
          width: 100%;
        }
      }
    }
  }

  .store-setup {
    .card .card-body {
      flex-wrap: wrap;
      .mb-2.header {
        max-width: 100%;
        width: 100%;
      }
    }
  }
  .row.wrapR {
    flex-wrap: wrap-reverse !important;
    .google-map {
      height: 700;
      margin-top: 24px;
    }
    .col-xs-12,
    .col-sm-12 {
      width: 100% !important;
      max-width: 100% !important;
      flex: 0 0 100%;
    }
  }
  .website-widget {
    .kit__utils__heading {
      flex-wrap: wrap;
      h5 {
        margin-bottom: 15px;
      }
      .wcode {
        margin-left: 0 !important;
      }
    }
    .widget-container {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .color-picker-input {
      .btn-block {
        padding: 0% !important;
        border-radius: 4px;
      }
    }
  }
  .integrations {
    .ant-row {
      flex-wrap: wrap !important;
      .ant-col-12 {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }
  .collapse-bar-block {
    height: 0 !important;
    max-height: 0 !important;
    overflow: hidden !important;
    padding: 0 !important;
  }
  .input-form-container.coupon {
    flex-wrap: wrap;
    overflow-y: auto !important;
    .mb-2.header {
      max-width: 100%;
      margin-right: 0;
    }
    .coupon-form-block {
      margin-bottom: 180px !important;
    }
    .form-footer {
      position: fixed;
      padding-top: 16px !important;
      padding-bottom: 15px !important;
      .ant-form-item {
        margin-bottom: 0px !important;
      }
    }
  }

  .input-form-container.employee {
    flex-wrap: wrap;
    overflow-y: auto !important;
    .mb-2.header {
      max-width: 100%;
      margin-right: 0;
    }
  }
  .menu-manager-page {
    .card {
      border: 0 !important;
      box-shadow: none !important;
      .card-body {
        padding: 0 !important;
        button {
          margin-right: 0 !important;
        }
        .ant-tabs-mobile {
          margin-top: 40px;
          .ant-btn.ant-btn-secondary.ant-btn-lg {
            float: left !important;
          }
          input.ant-input {
            margin-top: 16px;
            margin-left: auto !important;
          }
          .ant-collapse-header {
            .row {
              .col {
                flex-grow: unset;
                width: 100%;
                flex-basis: unset;
                text-align: left !important;
              }
              .col-2 {
                flex: 0 0 100%;
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .input-form-container {
    .card .card-body {
      flex-wrap: wrap;
      .row.d-header {
        max-width: 100% !important;
      }
    }
  }
}

.widget-container {
  max-width: 850px !important;
}

.bd-highlight.field.mttop {
  margin-top: 12px;
}
.card.show {
  display: block !important;
}

.red {
  background-color: #e80707;
}

.free-delivery-block {
  padding-bottom: 12px;
}

.select-btn.ant-btn.ant-btn-primary:focus {
  color: #262626 !important;
}

.galleryimageuploaditem .ant-form-item-control-input {
  bottom: 15px;
}
.promotionimageuploaditem .ant-form-item-control-input {
  bottom: 15px;
}

.themeDesign .form-footer {
  width: calc(100%) !important;
}

.themeDesign .col-9 {
  flex: 0 0 75% !important;
  max-width: 75% !important;
}

.social-media .col-9 {
  flex: 0 0 75% !important;
  max-width: 75% !important;
}

.gallery-section .col-12 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.popup-section .col-12 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.social-media .form-footer {
  width: calc(100%) !important;
}

.website-widget .col-9 {
  flex: 0 0 75% !important;
  max-width: 75% !important;
}

@media (max-width: 480px) {
  .themeDesign .col-9 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .social-media .col-9 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .gallery-section .col-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .popup-section .col-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .website-widget .col-9 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .widget-container .form-footer {
    width: calc(100% - 0px);
  }

  .gallery-section .d-flex {
    display: flex !important;
    padding-top: 25px !important;
  }

  .gallery-section .d-flex .style_floatingCreateButton__3Z9d0 {
    position: absolute !important;
    right: 30px !important;
    top: 40px !important;
  }

  .gallery-section .d-flex .style_floatingCreateButton_upload__2omVu {
    position: absolute !important;
    right: 195px !important;
    top: 40px !important;
  }

  .gallery-section {
    position: relative !important;
    width: 100% !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .themeDesign .preview-button {
    display: flex !important;
    padding-top: 0 !important;
  }

  .themeDesign .preview-button .style_floatingCreateButton__3Z9d0 {
    position: absolute !important;
    right: 30px !important;
    top: 9px !important;
  }

  .social-media .preview-button {
    display: flex !important;
    padding-top: 0 !important;
  }

  .social-media .preview-button .style_floatingCreateButton__3Z9d0 {
    position: absolute !important;
    right: 30px !important;
    top: 9px !important;
  }

  .popup-section .d-flex {
    display: flex !important;
    padding-top: 25px !important;
  }

  .popup-section .d-flex .style_floatingCreateButton__3Z9d0 {
    position: absolute !important;
    right: 30px !important;
    top: 40px !important;
  }

  .popup-section .d-flex .style_floatingCreateButton_upload__2omVu {
    position: absolute !important;
    right: 195px !important;
    top: 40px !important;
  }
  .popup-section {
    position: relative !important;
    width: 100% !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .page-form_divider__2kbcc {
    border-top: 1px dashed #d2ddec !important;
    margin: 0 0 !important;
  }
}

.removal-modal {
  .ant-modal-body {
    padding: 32px 32px 24px !important;
  }
  .font-size-16 {
    display: flex !important;
    overflow: hidden;
    color: #222 !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 1.4 !important;
    padding-right: 24px;
    .anticon-exclamation-circle {
      margin-top: 3px;
    }
  }
  .text-right {
    margin-top: 24px !important;
    .ant-btn {
      height: 32px !important;
    }
    .ant-btn-primary {
      margin-left: 12px !important;
    }
  }
}

.tax-search {
  .ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
    border-radius: 8px !important;
  }
  .ant-input-group-addon {
    display: none !important;
  }
}

.available-hours {
  .ant-radio-wrapper {
    display: block !important;
  }
}

.import-modal {
  width: 740px !important;
  .ant-modal-body {
    padding: 12px 24px !important;
  }
  .modal-header-sub {
    margin-top: 6px;
    font-size: 14px !important;
    color: #8e8e8e !important;
    line-height: 16px;
    font-weight: normal !important;
  }
  h4 {
    margin: 16px 0;
    font-size: 18px !important;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      .warning.text-icon {
        color: #f86d64;
      }
      .text-icon {
        color: #262626;
        font-size: 16px;
      }
      .text-head {
        width: 200px;
        display: inline-block;
        padding-left: 8px;
      }
    }
  }
  .ant-table-cell {
    padding: 6px 12px !important;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 4px !important;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 4px !important;
  }
  .btn-block {
    margin: 16px 0;
  }
}

.fade-in-image {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn s;
}
@keyframes fadeIn {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.sec-divider {
  border-bottom: 1px dashed #e4e9f0;
  width: 100%;
  margin-bottom: 12px;
}

.confirmation-modal {
  .ant-modal-footer {
    border-top: 0 !important;
    .ant-btn-primary {
      height: 32px !important;
      padding: 0 20px !important;
    }
  }
}

.website-manager .themeDesign .bd-highlight.color-input-field {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.swiper-slide.swiper-slide-active .box {
  z-index: 999999;
  perspective: 1000px;
}

.ant-layout-sider {
  border-right: 1px solid #e4e9f0;
}

.top-block-item {
  line-height: 1.6;
  color: #757575;
}

.top-block-item:last-child {
  margin-left: -21px;
}

.ant-layout-content {
  background-color: #fafafa;
}

.payfacto-files-page {
  .dragger-block {
    padding: 24px !important;
    .ant-upload-text {
      font-weight: 500;
    }
    .ant-upload-hint {
      text-align: left;
      margin-top: 18px;
      color: rgba(0, 0, 0, 0.65) !important;
      .upload-header {
        span.black {
          font-weight: 500;
          color: #333 !important;
        }
        a {
          color: #00b1e1;
        }
      }
      ul {
        padding-left: 0px;
        margin-left: 0px;
        margin-top: 12px;
        list-style: none;
        li {
          margin-bottom: 5px;
        }
      }
    }
  }
  .dragger-block,
  .ant-upload-list {
    max-width: 800px;
    margin: auto !important;
    background-color: #fff !important;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  text-shadow: none !important;
}

.masters-page,
.classifications-page,
.additinal_section {
  .ant-tabs-nav-list {
    transform: translate(15px, 0px) !important;
  }

  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 2px 2px 0 0 !important;
    margin-right: 4px !important;
  }
}

.ant-table-tbody {
  background: white;
  min-height: 500px;
}

.ant-drawer-header-title {
  width: 100%;
  flex-direction: row-reverse;
}

.form-drawer .ant-drawer-content-wrapper div.ant-drawer-body {
  padding: 0px !important;
  padding-top: 16px !important;
  .ant-row.ant-form-item {
    padding-left: 24px;
    padding-right: 24px;
  }

  .ant-row.ant-form-item.footer-block {
    padding-left: 0px;
    padding-right: 0px;
    position: absolute;
    bottom: -14px;
    background: #fafafa;
    width: 100%;
    border-top: 1px solid #ddd;
    height: 65px;
    .ant-form-item-control-input-content {
      text-align: right;
      padding-top: 12px;
      padding-right: 24px;
    }
  }
}

.form-drawer .ant-drawer-content-wrapper {
  max-width: 600px !important;
}

.ant-select-selection-placeholder {
  font-size: 14px !important;
  color: rgba(142, 142, 142, 8.1) !important;
}

.form-page.publication-form-page {
  max-width: 800px;
  .ant-form-vertical {
    display: block;
    .ant-row.ant-form-item:nth-child(even) {
      margin-left: 20px;
    }
    // .ant-row.ant-form-item:nth-child(3),
    // .ant-row.ant-form-item:nth-child(4),
    // .ant-row.ant-form-item:nth-child(5) 
    // {
    //   width: calc(33% - 24px);
    // }
    // .ant-row.ant-form-item:nth-child(5) {
      // margin-left: 20px;
    // }
    // .ant-row.ant-form-item:nth-child(6) {
    //   width: 100%;
    //   margin-left: 0px;
    //   padding-right: 42px;
    //   text-align: right;
    // }
    .ant-row.ant-form-item:last-child {
      margin-top: 24px;
    }
    .ant-row.ant-form-item {
      width: calc(50% - 30px);
      display: inline-block;
    }
  }
}

.issueDate-form-page{
  margin-left: 24px;
}

.additinal_section {
  max-width: 1000px;
  position: relative;
  .add_sec_btn {
    position: absolute !important;
    right: 0px !important;
    z-index: 10;
  }
  .section-pub-page {
    display: flex;
    .side-bar {
      min-width: 200px;
      max-width: 200px;
      width: 100%;
      border-right: 1px solid #e4e9f0;
      ul {
        list-style: none;
        padding-left: 0px;
        margin-left: 0px;
        li.selected {
          background-color: #fff;
          font-weight: 600;
        }
        li {
          font-size: 14px;
          cursor: pointer;
          position: relative;
          padding-right: 40px !important;
          height: 36px;
          span.txt {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            display: inline-block;
            padding: 8px 12px;
          }
          .del-icon {
            position: absolute !important;
            right: 10px;
            top: 12px;
          }
        }
      }
    }
    .section-content {
      width: 100%;
      padding: 24px;
      .publication-sections-form-page {
        .ant-form-vertical {
          .ant-row.ant-form-item {
            width: calc(50% - 30px);
            display: inline-block;
            margin-right: 24px;
          }
          .footer-block.ant-row.ant-form-item {
            width: 100%;
            padding-right: 36px;
            text-align: right;
          }
        }
      }
      .additinal_section_2 {
        position: relative;
        .add_btn {
          position: absolute;
          right: 0px;
          z-index: 10;
        }
      }
    }
  }
}

.list-view {
  .ant-card {
    margin-bottom: 8px !important;
    cursor: pointer;
    .ant-card-body {
      padding: 12px 24px;
    }
  }
}

.loading-sec-content {
  text-align: center;
  height: 100%;
  width: 100%;
  margin-top: 80px;
}

.ant-popover-placement-top {
  z-index: 999999;
}

.overlay-sec {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  .bg-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 20;
    opacity: 0.4;
  }
  .ant-spin {
    margin-top: 48px !important;
  }
}

.sec-item {
  .overlay-sec {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999999;
    margin-top: 0px;
    .ant-spin{
      margin-top: 12% !important;
    }
  }
}
