@import "mixins.scss";

#nprogress .spinner {
  display: none;
}

.ant-form-item {
  margin-bottom: 14px !important;
}

.ant-form-item-label {
  padding: 0 0 2px 0 !important;
}

.ant-table-cell {
  font-size: 1rem;
}

.ant-drawer-body {
  background-color: $gray-1;
}

code {
  padding: 5px 10px;
  display: inline-block;
}

.pin-container {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-column-gap: 8px;
  justify-content: center;
}

.pin-field {
  width: 32px;
  height: 32px;
  font-size: 16px;
  text-align: center;
  outline: none;
  border-radius: 6px;
  border: 1px solid $dark-gray-1;
  transition-property: color, border, box-shadow, transform;
  transition-duration: 250ms;

  &:focus {
    outline: none;
    box-shadow: 0 0 7px rgba($blue, 0.5);
    border: 1px solid $blue;
    transform: scale(1.05);
  }
}

.pin-field-large {
  width: 36px;
  height: 36px;
  font-size: 18px;
  text-align: center;
  outline: none;
  border-radius: 6px;
  border: 1px solid $dark-gray-1;
  transition-property: color, border, box-shadow, transform;
  transition-duration: 250ms;

  &:focus {
    outline: none;
    box-shadow: 0 0 7px rgba($blue, 0.5);
    border: 1px solid $blue;
    transform: scale(1.05);
  }
}

.pin-field-complete {
  border: 1px solid $green;
  color: $green;

  &[disabled] {
    background: rgba($green, 0.1);
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.border-gray-3 {
  border-color: #f2f3f7 !important;
}

.set-bottom .ant-menu-item.ant-menu-item-only-child span {
  padding-left: 8px !important;
}

.logo-img {
  width: 120px !important;
  height: auto !important;
}

.layout-icon-selected {
  border: 2px solid #262626 !important;
}

.coupon-drawer.form-drawer {
  .ant-drawer-body {
    overflow-y: hidden !important;
  }
}

.form-drawer {
  .ant-drawer-content-wrapper {
    max-width: 900px;
    .ant-drawer-body {
      padding: 5px !important;
      background-color: #fff !important;
    }
  }
}

.form-drawer.small {
  .ant-drawer-content-wrapper {
    max-width: 850px;
  }
}

.form-drawer.v-small {
  .ant-drawer-content-wrapper {
    max-width: 700px;
  }
}

.form-drawer.tax .input-form-container {
  display: flex;
  width: 100%;
  .header {
    width: 100%;
    max-width: 200px;
    margin-right: 40px;
  }
  .labels-container {
    width: 100%;
  }
}

.input-form-container.coupon {
  height: calc(100vh - 100px);
  // overflow-y: scroll;
  margin-top: 0 !important;
  display: flex;
  .header {
    width: 100%;
    max-width: 200px;
    margin-right: 40px;
  }
  .coupon-form-block {
    margin-bottom: 90px;
    width: 100%;
    overflow-y: scroll;
    .ant-form-item-label {
      margin-bottom: 5px !important;
      font-weight: 600 !important;
      font-size: 1rem !important;
      color: #12263f !important;
    }
    .ant-radio-group {
      display: flex;
      .ant-radio-button-wrapper.second-btn {
        margin-left: 24px;
      }
      .ant-radio-button-wrapper-checked.ant-radio-button-wrapper {
        background-color: #f0f5f8;
      }
      .ant-radio-button-wrapper:not(:first-child)::before {
        opacity: 0 !important;
      }
      .ant-radio-button-wrapper:focus-within {
        box-shadow: none;
        outline: none;
      }
      .ant-radio-button-wrapper {
        height: 80px;
        width: 100%;
        padding: 35px 15px 0 15px;
        background-color: #fff;
        border: 1px solid #e4e9f0 !important;
        font-size: 14px;
        color: #262626;
        border-radius: 6px;
        text-align: center;
        .ant-radio-button {
          top: 15px;
          z-index: 0;
          text-align: center;
          .ant-radio-button-input::after {
            border-radius: 50%;
            top: -2px;
            left: -1px;
            position: relative;
            content: "";
            display: inline-block;
            visibility: visible;
            width: 20px !important;
            background-color: #fff;
            border: 1px solid #262626;
            height: 20px !important;
            transition: 0.2s ease-in-out;
          }
          .ant-radio-button-input:checked:after {
            border: 4px solid #262626;
            background-color: #fafafa;
          }
          .ant-radio-button-input {
            opacity: 1 !important;
            visibility: hidden;
            width: 20px !important;
            height: 20px !important;
          }
        }
      }
    }
  }
}

.input-form-container.employee {
  height: calc(50vh - 100px);
  overflow-y: scroll !important;
  margin-top: 0 !important;
  display: flex;
  .header {
    width: 100%;
    max-width: 200px;
    margin-right: 40px;
  }
  .employee-form-block {
    margin-bottom: 90px;
    width: 100%;
    overflow-y: scroll;
    .ant-form-item-label {
      margin-bottom: 5px !important;
      font-weight: 600 !important;
      font-size: 1rem !important;
      color: #12263f !important;
    }
    .ant-radio-group {
      display: flex;
      .ant-radio-button-wrapper.second-btn {
        margin-left: 24px;
      }
      .ant-radio-button-wrapper-checked.ant-radio-button-wrapper {
        background-color: #f0f5f8;
      }
      .ant-radio-button-wrapper:not(:first-child)::before {
        opacity: 0 !important;
      }
      .ant-radio-button-wrapper:focus-within {
        box-shadow: none;
        outline: none;
      }
      .ant-radio-button-wrapper {
        height: 80px;
        width: 100%;
        padding: 35px 15px 0 15px;
        background-color: #fff;
        border: 1px solid #e4e9f0 !important;
        font-size: 14px;
        color: #262626;
        border-radius: 6px;
        text-align: center;
        .ant-radio-button {
          top: 15px;
          z-index: 0;
          text-align: center;
          .ant-radio-button-input::after {
            border-radius: 50%;
            top: -2px;
            left: -1px;
            position: relative;
            content: "";
            display: inline-block;
            visibility: visible;
            width: 20px !important;
            background-color: #fff;
            border: 1px solid #262626;
            height: 20px !important;
            transition: 0.2s ease-in-out;
          }
          .ant-radio-button-input:checked:after {
            border: 4px solid #262626;
            background-color: #fafafa;
          }
          .ant-radio-button-input {
            opacity: 1 !important;
            visibility: hidden;
            width: 20px !important;
            height: 20px !important;
          }
        }
      }
    }
  }
}

.order-detail-drawer {
  .ant-drawer-content-wrapper {
    max-width: 630px !important;
  }
  .ant-drawer-body {
    background-color: #fff !important;
    padding: 0 16px 0 16px;
  }
}

.input-form-container {
  margin-top: 16px !important;
  .labels-container {
    .ant-form-item-label {
      margin-bottom: 5px !important;
      font-weight: 600 !important;
      font-size: 1rem !important;
      color: #12263f !important;
    }
  }
  .card {
    background-color: #fff !important;
    border: 0 !important;
    border-bottom: 1px dashed #d2ddec !important;
    margin: 0 1.66rem;
    .card-body {
      display: flex;
      border-color: unset !important;
      box-shadow: none !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      .last-card.row {
        margin-right: 0 !important;
        margin-bottom: 120px !important;
      }
      .row.d-header {
        width: 100%;
        max-width: 300px;
        margin-right: 24px;
      }
      .row {
        width: 100%;
        // h4.header{
        //   max-width: 240px;
        // }
        .ant-form-item-label {
          margin-bottom: 5px !important;
          font-weight: 600 !important;
          font-size: 1rem !important;
          color: #12263f !important;
        }
        .dashborder.top0 {
          padding-top: 0 !important;
        }
        .dashborder {
          padding-bottom: 20px;
          padding-top: 20px;
          border-bottom: 1px dashed #d2ddec !important;
          h5 {
            position: relative;
            color: #222;
            font-size: 14px;
            font-weight: 600 !important;
          }
        }
      }
    }
  }
  .form-footer {
    box-shadow: 0 -6px 5px -4px rgba(0, 0, 0, 0.1);
    left: 18px;
    bottom: 0 !important;
    position: absolute;
    padding: 24px 15px;
    border-top: 1px dashed #d2ddec;
    z-index: auto;
    background-color: #f9fbfd;
    transition: box-shadow 0.2s linear;
    width: calc(100% - 36px);
    .center-align {
      text-align: center !important;
    }
  }
}

::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.4) !important;
  font-size: 14px !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0, 0, 0, 0.4) !important;
  font-size: 14px !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.4) !important;
  font-size: 14px !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(0, 0, 0, 0.4) !important;
  font-size: 14px !important;
}

[data-vb-theme="default"] .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #262626;
  font-weight: 500;
}

[data-vb-theme="default"] .ant-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
  color: #8e8e8e;
}

[data-vb-theme="default"] .ant-tabs-ink-bar {
  background: #262626;
}

[data-vb-theme="default"] .ant-switch {
  background-color: #8e8e8e;
}

[data-vb-theme="default"] .ant-switch.ant-switch-checked {
  background: #262626;
}

.ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  background-color: #8e8e8e !important;
  color: white;
}

[data-vb-theme="default"] .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #efefef;
}

[data-vb-theme="default"] .ant-menu-submenu-selected {
  .ant-menu-submenu-title {
    color: #262626 !important;
    font-weight: 600;
  }
}
.ant-menu-item.ant-menu-item-only-child.ant-menu-item-selected {
  color: #262626 !important;
  font-weight: 600;
}

[data-vb-theme="default"] ul.ant-menu-vertical .ant-menu-item-selected {
  background-color: #8e8e8e !important;
  color: #fff !important;
  a {
    color: #fff !important;
  }
}

ul.ant-menu-vertical li.ant-menu-item.ant-menu-item-only-child.ant-menu-item-active {
  background-color: #efefef !important;
}

.hide {
  display: none;
}

b,
strong {
  font-weight: 600 !important;
}

.no-border {
  border: 0 !important;
  box-shadow: none !important;
}

.website-widget {
  margin-bottom: 100px;
  max-width: 1200px;
  .kit__utils__heading {
    padding-bottom: 16px;
    border-bottom: 1px solid #d2ddec;
    display: flex;
    width: 100%;
  }
  h5 {
    width: 100%;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 0 !important;
  }
  .d-flex {
    display: flex;
  }
  .widget_layout {
    .ant-radio-wrapper {
      display: block !important;
      margin: 10px 0;
      color: #262626;
    }
  }
}

.timing-billing {
  font-size: 14px !important;
}

.font-weight-semi-bold {
  font-weight: 500 !important;
}

.form-footer {
  box-shadow: 0 -6px 5px -4px rgba(0, 0, 0, 0.1);
  left: 18px;
  position: fixed;
  bottom: 0 !important;
  padding: 24px 15px;
  border-top: 1px dashed #d2ddec;
  z-index: auto;
  background-color: #f9fbfd;
  transition: box-shadow 0.2s linear;
  width: calc(100% - 48px);
  text-align: center;
  .center-align {
    text-align: center !important;
  }
}

.form-footer.w100 {
  width: 100% !important;
}

button.ant-btn-primary.btn-play-pause {
  padding-left: 32px !important;
  .fe.btn-pause-play {
    font-size: 18px;
    min-width: 16px;
    position: absolute !important;
    top: 10px !important;
    left: 8px !important;
  }
}

.ant-layout-content {
  position: relative;
}
.ant-menu.ant-menu-sub.ant-menu-inline .ant-menu-item.ant-menu-item-selected {
  color: #262626 !important;
  font-weight: 600;
}

.ant-menu-inline.ant-menu .ant-menu-item.ant-menu-item-selected {
  color: #262626 !important;
  font-weight: 600;
}

.plantag {
  background: #262626;
  font-size: 13px;
  color: white !important;
  border-radius: 4px;
  padding: 2px 10px;
  width: auto;
  margin: 0 auto;
}

.change-plan-block {
  margin-bottom: 18px;
  text-align: right;
  .ant-btn-primary {
    font-size: 16px;
    padding: 5px 18px;
    height: 36px;
  }
}

$color-alpha: #b0444a;
$color-beta: #ffd8da;
$color-gamma: #b0444a;

.billing-block {
  .overlay {
    position: fixed;
    width: 100%;
    background: #fff;
    height: calc(100% + 124px);
    top: -124px;
    left: 0;
    opacity: 0.85;
  }
  .plan-detail-list.hover-effect {
    &:hover {
      transform: translateY(-2em);
      &:after {
        width: 75%;
        transform: translateY(2em);
        opacity: 1;
        background-color: #eee;
      }
    }
  }
  .plan-detail-list {
    display: flex;
    position: absolute;
    width: 100%;
    max-width: 940px;
    padding: 20px;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    justify-content: center;
    .box.mr {
      margin-right: 24px;
    }
    .box {
      background-color: #fff;
      border: 1px solid #262626;
      border-radius: 4px;
      width: 400px;
      text-align: center;
      padding: 1em;
      position: relative;
      transition: 0.3s all ease-in-out;
      padding-bottom: 60px !important;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .top-block {
        width: 100%;
        height: calc(100% - 20px);
        p {
          margin-bottom: 12px !important;
        }
      }
      .bottom-block {
        width: 100%;
      }
      .plan-type {
        margin-bottom: 24px;
        .helper-text {
          margin-top: 4px;
          text-align: left;
          a {
            color: #007bff !important;
            text-decoration: underline !important;
          }
        }
        .ant-radio-button-wrapper-disabled {
          opacity: 0.7 !important;
        }
        .plan-item {
          display: flex;
          margin-bottom: 12px;
        }
        .plan-item .label-item {
          margin-right: 16px;
          width: 100px;
          text-align: left;
        }
        .ant-form-item-explain-error {
          text-align: left;
        }
        .ant-radio-group {
          display: flex;
          .ant-radio-button-wrapper.second-btn {
            margin-left: 24px;
            // padding-top: 34px;
            // .ant-radio-button {
            //   top: 15px;
            // }
          }
          .ant-radio-button-wrapper-checked.ant-radio-button-wrapper {
            background-color: #f0f5f8;
          }
          .ant-radio-button-wrapper:not(:first-child)::before {
            opacity: 0 !important;
          }
          .ant-radio-button-wrapper:focus-within {
            box-shadow: none;
            outline: none;
          }
          .ant-radio-button-wrapper {
            height: 80px;
            width: 100%;
            max-width: 180px;
            margin: auto;
            padding: 28px 15px 0 15px;
            background-color: #fff;
            border: 1px solid #e4e9f0 !important;
            font-size: 14px;
            color: #262626;
            border-radius: 6px;
            text-align: center;
            span {
              line-height: 20px !important;
              display: block;
              margin-top: 12px;
            }
            .ant-radio-button {
              top: 5px;
              z-index: 0;
              text-align: center;
              .ant-radio-button-input::after {
                border-radius: 50%;
                top: -2px;
                left: -1px;
                position: relative;
                content: "";
                display: inline-block;
                visibility: visible;
                width: 20px !important;
                background-color: #fff;
                border: 1px solid #262626;
                height: 20px !important;
                transition: 0.2s ease-in-out;
              }
              .ant-radio-button-input:checked:after {
                border: 4px solid #262626;
                background-color: #fafafa;
              }
              .ant-radio-button-input {
                opacity: 1 !important;
                visibility: hidden;
                width: 20px !important;
                height: 20px !important;
              }
            }
          }
        }
      }
      h1 {
        font-weight: 300;
      }
      .icon {
        font-size: 3em;
        margin: 1rem 0 1.5rem 0;
        color: #555;
      }
      p {
        color: #737373;
        margin-bottom: 3em;
        z-index: 15;
        strong {
          color: #555;
        }
      }
      .select-btn {
        color: #fff;
        font-size: 1.25em;
        padding: 0.5em 1em;
        display: inline-block;
        border-radius: 4px;
        transition: all 0.5s ease;
        background-color: #262626;
        height: auto;
        margin-bottom: 12px;
      }
      .disabled {
        opacity: 0.5 !important;
        pointer-events: none !important;
      }
      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 0;
        width: 100%;
        height: 4px;
        background-color: #aaa;
        transition: all 0.5s ease;
        opacity: 0;
      }
    }
  }
}

.notification-card {
  background-color: #262626;
  color: white;
  padding: 7px 16px;
  text-align: center;
  width: 100%;
  height: 46px;
  line-height: 0px;
  .ant-btn-primary:hover {
    background-color: #fff !important;
    color: #000 !important;
  }
  .ant-btn-primary {
    margin-left: 24px;
    background-color: #fff !important;
    color: #000;
  }
}

.vb__layout__fixedHeader {
  height: 35px !important;
}

.sub-header {
  position: relative;
  margin-top: 12px !important;
  line-height: 1.5 !important;
  font-size: 1rem !important;
  color: #8e8e8e !important;
  padding-right: 24px;
  font-weight: normal !important;
}

.form-item-desc {
  margin-top: -20px;
  color: #8e8e8e !important;
}

button.btn-primary {
  font-size: 16px;
}

button.ant-btn.ant-btn-circle {
  min-width: 32px !important;
  height: 32px !important;
  padding: 0 !important;
  text-align: center !important;
  border-radius: 50% !important;
}

.upload-img-input {
  .ant-form-item-explain {
    position: absolute;
    top: -5px;
    div {
      font-size: 13px;
    }
  }
  .ant-form-item-control-input {
    margin-top: 30px;
  }
}

.help-txt-input {
  .ant-form-item-explain {
    position: absolute;
    top: -5px;
    div {
      font-size: 13px;
    }
  }
  .ant-form-item-control-input {
    margin-top: 42px;
  }
}

.helper-text {
  clear: both;
  min-height: 24px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 13px;
  line-height: 1.5715;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  padding-top: 0;
}

.tax-input-form {
  .ant-form-item-explain {
    position: absolute;
    top: -5px;
    div {
      font-size: 13px;
    }
  }
  .ant-form-item-control-input {
    margin-top: 24px;
  }
}

.min-wth300 {
  min-width: 300px;
}

.form-groups-list {
  margin-top: 12px;
  .ant-row.ant-form-item {
    width: 100%;
    flex-wrap: wrap;
    .ant-form-item-label {
      width: 100%;
      text-align: left;
      label {
        height: auto;
        margin-bottom: 0%;
        font-weight: 500 !important;
      }
    }
  }
  .ant-input-number {
    width: 100% !important;
    border-radius: 4px;
  }
  input.ant-input {
    border-radius: 4px;
    height: 40px;
  }
}

.nodata-found-block.min-ht {
  min-height: 300px;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}
.nodata-found-block {
  text-align: center;
  .nodata-found {
    text-align: center;
    .nodata-found-img {
      width: 60px;
    }
    .nodata-text {
      margin-top: 24px;
      font-size: 14px;
      color: #c8c4db;
    }
  }
}

button.ant-btn-primary {
  padding: 7px 24px !important;
  border-radius: 5px !important;
  height: 40px !important;
}

button.ant-btn {
  border-radius: 5px !important;
}

.ant-popover-inner-content button.ant-btn.ant-btn-primary {
  height: 24px !important;
  padding: 0 7px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
}

.ant-modal-confirm-btns button.ant-btn.ant-btn-primary {
  padding: 4px 15px !important;
  height: 32px !important;
  border-radius: 5px !important;
  border: 0 !important;
}

.removal-modal .text-right .ant-btn-primary {
  padding: 4px 15px !important;
}

.notification-card .ant-btn-primary {
  padding: 4px 15px !important;
  height: 32px !important;
  border-radius: 5px !important;
  font-weight: 500 !important;
}

.ant-btn.ant-btn-primary.ant-btn-sm.manage-btn,
.device-card .ant-btn.ant-btn-primary {
  padding: 4px 15px !important;
  height: 30px !important;
  border-radius: 5px !important;
}

.promotionimageuploaditem .ant-btn.ant-btn-primary,
.galleryimageuploaditem .ant-btn.ant-btn-primary {
  margin-right: 16px;
  height: 40px !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-top: -2px;
}

.billing-close-btn.ant-btn {
  position: absolute;
  top: -80px;
  right: 8px;
  z-index: 999999;
  perspective: 1000px;
  border: 0 !important;
}

.upload-img-input {
  .ant-upload-select-picture-card {
    margin-right: 0px !important;
    margin-bottom: 0px !important;
    img {
      border-radius: 8px !important;
      width: 104px;
      height: 104px;
      object-fit: cover;
    }
  }
  .ant-form-item-control-input-content {
    position: relative;
    max-width: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .upload-icon {
    color: #262626;
    font-size: 15px;
  }
  .remove-icon-container {
    transition: 0.2s ease-in-out;
    z-index: -1;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .overlay,
  .remove-icon-container {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    opacity: 0.3;
  }
  .overlay {
    background-color: #000;
  }
  .ant-form-item-control-input-content:hover .remove-icon-container {
    opacity: 1;
    z-index: 1;
  }
  .ant-form-item-control-input-content:hover .remove-icon-block {
    opacity: 1 !important;
  }
  .remove-icon-block {
    position: absolute;
    background-color: #fff;
    width: 35px;
    height: 35px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s ease-in-out;
    opacity: 0;
    cursor: pointer;
  }
}

@media screen and (max-width: 500px) {
  .time-sheet-reports {
    .custom-table{
      overflow-x: auto;
    }
    .filter-block-input {
      flex-wrap: wrap;
      .role-select {
        min-width: 240px;
        width: auto;
        margin-left: auto !important;
      }
      .ant-select-selector {
        text-align: left;
      }
      .ant-select-arrow {
        top: 22px;
      }
    }
  }
  .page-loader-block {
    margin-left: 0 !important;
  }
  .top-bar-sec {
    padding: 0 !important;
    height: auto !important;
    .notification-card {
      display: flex;
      align-items: center;
      line-height: 1.2;
      text-align: left;
    }
  }
  .wrap-card .card-header .d-flex {
    flex-wrap: wrap;
    .ant-radio-group {
      margin-top: 6px;
    }
  }
  .filter-block {
    display: flex;
    flex-wrap: wrap;
    div {
      width: 100%;
      text-align: right;
      margin-bottom: 24px;
      padding-bottom: 0 !important;
    }
    .filter-block-input {
      margin-bottom: 0px !important;
    }
  }
  .hours-select {
    .d-flex.align-items-start {
      flex-wrap: wrap;
      .w-50 {
        width: 100% !important;
      }
      .d-flex.ml-auto.p-1 {
        margin-left: 0 !important;
      }
    }
  }
  .menu-manager-page .card .card-body {
    .ant-tabs-mobile {
      .text-left {
        text-align: right !important;
        .ant-input-affix-wrapper {
          margin-top: 12px;
          input {
            margin-top: 0px;
          }
        }
      }
      .tax-tab {
        .tab-filter {
          display: flex;
          flex-wrap: wrap-reverse;
          .col-md-4 {
            text-align: right;
            width: 100%;
            margin-top: 12px;
            margin-bottom: 12px;
          }
          input.ant-input {
            margin-top: 0 !important;
          }
        }
      }
      .item-list {
        .col-icons {
          width: 100% !important;
          max-width: 100% !important;
          flex: auto !important;
        }
      }
      .col-m-icons {
        margin-left: 14px;
      }
    }
  }
  .input-form-container .card {
    margin-right: 0 !important;
    .upload-img-input {
      .ant-upload.ant-upload-select-picture-card {
        margin-top: 10px;
      }
    }
    .day-list {
      overflow-x: auto;
      .col-4 {
        min-width: 50%;
      }
      .day-name {
        flex: auto;
        max-width: 100%;
        margin-bottom: 8px;
      }
    }
  }
  .mobile-heading {
    display: block !important;
    .right-sec {
      text-align: right;
      margin-top: 12px;
    }
  }
  .delivery-zones {
    .bb-header-1 {
      display: block;
    }
  }
  .page-form-footer {
    width: calc(100% - 32px) !important;
    max-width: 100% !important;
  }
  .show-mobile {
    display: block !important;
    text-align: left !important;
  }
  .order-detail-drawer {
    .ant-drawer-content-wrapper {
      width: calc(100% - 0px) !important;
    }
  }
  .menu-manager-page .card .card-body .ant-tabs-mobile .ant-collapse-header .row .col.text-right {
    text-align: right !important;
    .anticon,
    .fe {
      font-size: 20px !important;
      padding: 6px 6px !important;
    }
  }

  .menu-manager-page .card .card-body .ant-tabs-mobile .row .col.text-right {
    text-align: right !important;
    .anticon,
    .fe {
      font-size: 20px !important;
      padding: 6px 6px !important;
    }
  }
  .menu-manager-page .card .card-body .ant-tabs-mobile .ant-collapse-header .row .col-2 {
    text-align: right !important;
    .anticon,
    .fe {
      font-size: 20px !important;
      padding: 6px 6px !important;
    }
    .row {
      justify-content: flex-end;
      align-items: center;
      padding-right: 12px;
    }
  }
  .eg_text {
    display: block !important;
    margin-left: 20px;
  }
  .sort-rows.m-opts {
    .ant-space {
      display: block !important;
      border-bottom: 1px dashed #ddd !important;
      margin-bottom: 24px !important;
      .ant-space-item:first-child,
      .ant-space-item:nth-child(2) {
        width: 100% !important;
      }
      .ant-space-item:nth-child(3) {
        width: calc(50% - 12px) !important;
        margin-right: 5px !important;
      }
      .ant-space-item:nth-child(4),
      .ant-space-item:nth-child(5),
      .ant-space-item:nth-child(6),
      .ant-space-item:nth-child(7),
      .ant-space-item:nth-child(8) {
        width: auto !important;
        margin-right: 5px !important;
      }
      .ant-space-item {
        margin-right: 0 !important;
        display: inline-block !important;
        .anticon {
          font-size: 20px !important;
          margin-right: 8px;
        }
      }
    }
  }
  body .getting-started .onboarding .onboarding.card {
    z-index: 0;
  }
  .online-order-setup .pos-r .overlay-block {
    width: 100%;
    left: 0;
    height: 72px;
    .overlay-content {
      p {
        font-size: 16px !important;
      }
    }
  }

  .style-form-1 {
    display: block !important;
    .ant-row.ant-form-item {
      flex-wrap: nowrap !important;
      flex-flow: unset;
      .ant-col.ant-form-item-label {
        flex: none;
        margin-right: 16px;
      }
    }
  }

  .billing-block {
    .billing-close-btn.ant-btn {
      top: -100px;
    }

    .plan-detail-list {
      top: 152px;
      .box {
        width: 100% !important;
        margin-left: 0 !important;
      }
    }
  }
  .order-coupon {
    display: none !important;
  }
}

.auth-container {
  .ant-select-auto-complete {
    width: 100%;
    .ant-select-selection-search-input {
      height: 40px;
    }
  }
}

.sort-rows.modify-rows .ant-space-horizontal {
  flex-wrap: wrap;
  border-bottom: 1px dashed #d2ddec;
  margin-bottom: 16px !important;
  .ant-space-item:nth-child(1),
  .ant-space-item:nth-child(2) {
    width: calc(50% - 20px) !important;
  }
  .anticon {
    font-size: 18px;
    margin-right: 16px;
  }
}

.view-more-block {
  text-align: center;
  .view-more {
    cursor: pointer;
    color: #262626;
    text-decoration: underline;
  }
}

.form-drawer.v-small.tax.converge {
  .ant-drawer-content-wrapper {
    max-width: 700px;
  }
}

.custom-table {
  table {
    table-layout: auto;
    width: 100%;
    text-align: left;
    border-radius: 8px 8px 0 0;
    border-collapse: separate;
    border-spacing: 0;
    .thead_name {
      text-align: center;
      th {
        text-align: center;
      }
    }
    thead tr > th {
      color: #222222;
      font-weight: 500;
      text-align: left;
      background: #f0f2f4;
      border-bottom: 1px solid #e4e9f0;
      transition: background 0.3s ease;
    }
    thead tr th,
    tr td {
      position: relative;
      padding: 16px 16px;
      overflow-wrap: break-word;
      font-size: 1rem;
    }
    tbody > tr > td {
      border-bottom: 1px solid #e4e9f0;
      transition: background 0.3s;
    }
  }
  .ant-picker {
    padding-left: 0px;
  }
}

.timesheets {
  .date-filter-block {
    background: #f0f2f4;
    width: 100%;
    padding: 8px 0;
    border-bottom: 1px solid #e4e9f0;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    text-align: center;
    .date-filter-flex {
      display: flex;
      justify-content: center;
      align-items: center;
      .anticon {
        color: #8e8e8e;
        font-size: 20px;
      }
      .ant-picker-suffix,
      .anticon-close-circle {
        display: none;
      }
      .ant-picker.date-filter {
        margin: 0 12px;
        cursor: pointer;
        background: transparent;
        width: 200px;
        text-align: center;
        border: 0px;
        padding-left: 0px !important;
        padding-right: 0 !important;
        outline: none;
        box-shadow: none;
        input {
          text-align: center;
        }
      }
    }
  }
  .ant-table-thead > tr > th {
    border-radius: 0 !important;
  }
  .ant-table-content {
    .cc_delete_icon {
      cursor: pointer;
      .anticon-delete {
        font-size: 18px;
        color: #262626;
      }
    }
  }

  .avatar-img img {
    width: 42px;
    height: 42px;
    object-fit: cover;
    border-radius: 50%;
    margin-left: 12px;
  }
}

.time-sheet-reports {
  .filter-block-input {
    display: flex;
    .role-select {
      margin-left: 24px;
      min-width: 240px;
      .ant-select-selector {
        height: 40px !important;
        .ant-select-selection-item {
          line-height: 40px;
        }
        .ant-select-selection-placeholder {
          line-height: 40px;
          color: #8e8e8e;
        }
      }
    }
  }
}

.ant-picker-ranges .ant-btn-primary {
  height: 28px !important;
  line-height: 0;
  padding: 7px 10px !important;
}
